import axios, { AxiosInstance } from 'axios'
import https from './config'

// const service = axios.create({
//     baseURL: http.baseUrl.dev
// })

export class Interceptors {
  instance: AxiosInstance
  constructor () {
    this.instance = axios.create({
      baseURL: https.baseUrl.dev,
      // baseURL: '',
      timeout: 25000
    })
  }

  // 初始化拦截器
  init () {
    // 请求接口拦截器
    this.instance.interceptors.request.use(
      config => {
        // 判断一下是否有cookie 如果有的话则把cookie放入请求头中
        // if (getToken()) {
        //     config.headers[getTokenKey()] = getToken();
        //     config.headers[getRefreshTokenKey()] = getRefreshToken();
        // }
        return config
      },
      err => {
        console.error(err)
      }
    )
    // 响应拦截器
    this.instance.interceptors.response.use(
      response => {
        const res = response.data
        if (!response.status.toString().startsWith('2') || res.code === -1) {
          // 如果状态码不是2开头或者接口code返回-1 则是返回错误信息
          console.error('系统错误，请检查API是否正常！')
          return
        }
        console.log('订单信息 078', res.code)
        return Promise.resolve(res)
        if (res.code !== 0) {
        } else {
          // 返回成功则把token存储一下
        //   const headers = response.headers;
        //   const token = headers.token;
        //   const refresh_token = headers["refresh-token"];
        //   if (token && refresh_token) {
        //     setToken(token);
        //     setRefreshToken(refresh_token);
        //   }
          return res
        }
      },
      err => {
        // if (err.message === "Request failed with status code 500") {
        //   console.error('系统错误，请检查API是否正常！');
        //   return;
        // }
        // let code = -110;
        // if (err && err.response && err.response.status) {
        //   code = err.response.status;
        //   // 登陆过期
        //   if (code === 401 || code === -3) {
        //   }
        // } else {
        //   console.error(err.message);
        // }
        // const err = { errCode: -110, errMsg: err.message || "Error" };
        return Promise.resolve(err)
      //   });
      }
    )
  }

  // 返回一下
  getInterceptors () {
    return this.instance
  }
}
