
import { defineComponent, reactive, onMounted, computed, toRefs } from 'vue'
import http from '@/api/index'
import { Toast, Dialog } from 'vant'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'Home',
  components: {},
  setup () {
    const store = useStore()
    const state = reactive({
      wenlist: computed(() => store.state.meuns),
      menus: [
        {
          url: './',
          name: '工伤速算赔偿'
        },
        {
          url: './',
          name: '工伤等级鉴定'
        },
        {
          url: './',
          name: '工伤案例精选'
        },
        {
          url: './',
          name: '工伤文书资料'
        },
        {
          url: './',
          name: '工伤法律法规'
        }
      ]
    })
    // const getData = async () => {
    //   const data = await http.get('portal/categories')
    //   data.data[0].url = '/wens'
    //   data.data[1].url = '/ans'
    //   data.data[2].url = '/ziliao'
    //   data.data[3].url = '/fagui'
    //   state.wenlist = data.data
    //   console.log('fuuuuuu', data.data)
    // }
    const showmess = (obj: any) => {
      Toast(obj.mess)
    }
    onMounted(() => {
      // getData()
    })
    return {
      // getData,
      showmess,
      ...toRefs(state)
    }
  }
})
