import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

type meun = {
  name: string,
  url: string,
  mess: string,
  thumbnail: string
}

export interface State {
  tings: boolean,
  meuns: Array<meun>
  // meuns: meun[]
}

export const key: InjectionKey<Store<State>> = Symbol('state key for easy get')

export const store = createStore<State>({
  state: {
    tings: false,
    meuns: []
  },
  mutations: {
    setting (state, status: boolean) {
      state.tings = status
    },
    getting: state => state.tings,
    setmeuns (state, arr: meun[]) {
      state.meuns = arr
    },
    getmeuns: state => state.meuns
  },
  actions: {
  },
  modules: {
  }
})

// 定义自己的 `useStore` 组合式函数
export function useStore () {
  return baseUseStore(key)
}
