
import { defineComponent, computed, ref, toRefs, reactive } from 'vue'
import { useStore } from 'vuex'
import {
  useRouter,
  useRoute
} from 'vue-router'
export default defineComponent({
  name: 'mheader',
  props: {
    sou: Boolean
  },
  setup (props, context) {
    const store = useStore()
    const keyword = ref<string>('')
    const show = ref<boolean>(false)
    const tings = computed(() => store.state.tings)
    const $router = useRouter()
    const $route = useRoute()
    const state = reactive({
      list: computed(() => store.state.meuns)
    })
    const openme = () => {
      show.value = true
    }
    const play = () => {
      context.emit('play')
    }
    const closeme = () => {
      show.value = false
    }
    const soume = () => {
      console.log('routerrrrr', $route.path)
      if ($route.path === '/list') {
        context.emit('search', keyword.value)
      } else {
        $router.push('/list?keyword=' + keyword.value)
      }
    }
    // const list = ref<string[]>([])
    return {
      keyword,
      show,
      openme,
      play,
      tings,
      soume,
      closeme,
      ...toRefs(state)
    }
  }
})
