import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import { axiosPlugin } from './api/axios'
import axios from 'axios'
import vueAxios from 'vue-axios'
import { vantPlugins } from './plugins/vant'
import myhead from '@/components/mhead.vue'
import './assets/reset.css'
import { Lazyload } from 'vant'

createApp(App).component('myhead', myhead).use(vueAxios, axios).use(store).use(router).use(axiosPlugin).use(vantPlugins).use(Lazyload).mount('#app')
