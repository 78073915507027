import { AxiosPromise, AxiosResponse } from 'axios'
import { Interceptors } from './service'
// import https from './config'
// import md5 from '@/assets/js/md5.min.js'

// 请求配置
export class HttpServer {
  axios: any
  // 获取axios实例
  constructor () {
    this.axios = new Interceptors().getInterceptors()
  }
  // 简单封装一下方法
  // request(config: any): AxiosPromise {
  //   return new Promise((resolve, reject) => {
  //     this.axios(config).then((res: AxiosResponse) => {
  //       resolve(res);
  //     }).catch((err: any) => {
  //       reject(err)
  //     });
  //   });
  // }

  get (url: string, params = {}): AxiosPromise {
    // const timer = this.getParams()
    return new Promise((resolve, reject) => {
      this.axios
        .get(url, {
        //   params: { ...timer, ...params }
          params: { ...params }
        })
        .then((response: AxiosResponse) => {
          resolve(response.data)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  down (url: string, params = {}): AxiosPromise {
    return new Promise((resolve, reject) => {
      this.axios({
        method: 'get',
        url,
        params: { ...params },
        responseType: 'blob'
      })
        .then((response: AxiosResponse) => {
          resolve(response.data)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  post (url: string, params = {}): AxiosPromise {
    // const timer = this.getParams()
    return new Promise((resolve, reject) => {
      this.axios
        .post(url, {
        //   params: { ...timer, ...params }
          params: { ...params }
        })
        .then((response: AxiosResponse) => {
          resolve(response.data)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  // getParams (): object {
  //   const times = Math.round(new Date().getTime() / 1000).toString()
  //   const mdtime = times.toString().substring(0, 5) + times.toString() + times.toString().substring(5)
  //   return {
  //     timestamp: times
  //     sign: md5(md5(mdtime))
  //   }
  // }
}
const http = new HttpServer()
export default http
