import { App as VM } from 'vue'
import {
  List,
  Cell,
  Image,
  Swipe,
  SwipeItem,
  Area,
  Icon,
  Picker,
  Popup,
  RadioGroup,
  Radio,
  Tab,
  Tabs,
  Overlay,
  Uploader
} from 'vant'

const plugins = [
  List,
  Cell,
  Image,
  Swipe,
  SwipeItem,
  Area,
  Icon,
  Picker,
  Popup,
  RadioGroup,
  Radio,
  Tab,
  Tabs,
  Overlay,
  Uploader
]

export const vantPlugins = {
  install: function (vm: VM) {
    plugins.forEach(item => {
      vm.component(item.name, item)
    })
  }
}
