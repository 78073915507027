
import http from '@/api/index'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  // components: {
  //   mhead
  // },
  setup () {
    const store = useStore()
    const getData = async () => {
      const data = await http.get('portal/categories')
      // data.data[0].url = '/form'
      data.data[0].url = '/wens'
      data.data[1].url = '/ans'
      data.data[2].url = '/ziliao'
      data.data[3].url = '/fagui'
      const umt1 = {
        name: '工伤等级鉴定',
        url: '',
        mess: '内容正在建设中',
        thumbnail: require('@/assets/img/go02.png')
      }
      data.data.unshift(umt1)
      const umt = {
        name: '工伤速算赔偿',
        url: '/form',
        thumbnail: require('@/assets/img/go03.png')
      }
      data.data.unshift(umt)
      const umt2 = {
        name: '联系我们',
        id: 152,
        url: '/info'
        // thumbnail: require('@/assets/img/go03.png')
      }
      data.data.push(umt2)
      const umt3 = {
        name: '公司简介',
        id: 153,
        url: '/info'
        // thumbnail: require('@/assets/img/go03.png')
      }
      data.data.push(umt3)
      // state.wenlist = data.data
      store.commit('setmeuns', data.data)
    }
    onMounted(() => {
      getData()
    })
    return {
      // ...toRefs(state)
    }
  }
})
